import React from 'react';

import Layout from '../../../components/layout';
import Seo from '../../../components/Seo';
import ActivityBreadCrumb from '../../../components/ActivityBreadCrumbs';
import RateActivity from '../../../components/RateActivity';

const WeAreTheAquanauts = ({ location }) => (
  <Layout page="activities" location={location}>
    <Seo
      manualTags={{
        seoTitle: 'We are the Aquanauts',
        seoDescription: '',
      }}
    />
    <section className="aq-feature-bg pb-8 pt-8 bg-grey">
      <ActivityBreadCrumb location={location} />
      <div className="container-fluid">
        <div className="component-wrap">
          <div className="row">
            <div className="col-lg-6 pb-4 pt-5 pb-lg-0">
              <h1 className="h1-two-line-v2 purple mb-12 mb-lg-16 mt-10">
                <span>We are the</span>
                <br />
                <span>Aquanauts!</span>
              </h1>
              <div
                style={{
                  position: 'absolute',
                  top: '160px',
                  left: '460px',
                  height: '135px',
                  width: '135px',
                  transform: 'rotate(180deg)',
                  zIndex: '10',
                }}
                className="d-none d-lg-block"
              >
                <img
                  src="/images/Doodles/Arrows/Arrow-3.svg"
                  alt="Illustration of an arrow"
                />
              </div>
              <div className="ml-0 ml-xl-15">
                <h3>
                  We are Isla, Nero and Kai and we’re here to help you learn
                  about water, and how precious it is.
                </h3>
                <p>
                  See if you can remember our names. (they’re all water related
                  in Spanish, Greek and Hawaiian!) We hope you have fun
                  colouring us in and getting to know us. Download and print the
                  sheet and grab some pencils or crayons; can you match the
                  colours to our Aquanaut uniforms?
                </p>
                <div style={{ position: 'relative' }}>
                  <a
                    href="https://cdn.southeastwater.co.uk/aquasmart/mission-1/SEW-We-are-the-Aquanauts-v2.pdf"
                    target="_blank"
                    className="btn btn-purple mt-3"
                    rel="noreferrer"
                  >
                    Download
                  </a>
                  <div
                    style={{
                      position: 'absolute',
                      top: '-80px',
                      left: '200px',
                      height: '135px',
                      width: '135px',
                      transform: 'rotate(180deg)',
                    }}
                    className="d-none d-lg-block"
                  >
                    <img
                      src="/images/Doodles/Arrows/Arrow-1.svg"
                      alt="Illustration of an arrow"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <img
                src="/images/Characters/Feature-BG-3.svg"
                className="w-100"
                alt="We are the Aquanauts colouring in sheet"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <RateActivity activity="We Are the Aquanauts" />
  </Layout>
);

export default WeAreTheAquanauts;
